<template>
  <div class="card p-3" v-if="stickyPeople && stickyPeople.id">
    <div class="flex items-center space-x-4">
      <div>
        <router-link v-if="stickyPeople.slug" :title="stickyPeople.title" class="text-lg font-semibold" :to="{name: 'PeopleDetail', params: {slug: stickyPeople.slug, prefix: getRoutePrefixPeople(stickyPeople.object_type)}}">
          <img :src="stickyPeople.avatar ? stickyPeople.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="h-32 w-32 object-cover rounded-full shadow-sm">
        </router-link>
      </div>
      <div class="">
        <p>
          <router-link v-if="stickyPeople.slug" :title="stickyPeople.title" class="text-lg font-semibold" :to="{name: 'PeopleDetail', params: {slug: stickyPeople.slug, prefix: getRoutePrefixPeople(stickyPeople.object_type)}}">
            {{ stickyPeople.title }}
          </router-link>
        </p>
        <p><span v-if="stickyPeople.dob">{{ stickyPeople.dob }}/</span><span v-if="stickyPeople.mob">{{ stickyPeople.mob }}/</span><span>{{ stickyPeople.yob }}</span> <span v-if="stickyPeople.yod"> - <span v-if="stickyPeople.dod">{{ stickyPeople.dod }}/</span><span v-if="stickyPeople.mod">{{ stickyPeople.mod }}/</span>{{ stickyPeople.yod }}</span></p>
        <p :uk-tooltip="'title: ' + thousandFormat(stickyPeople.total_listens) + ' lượt nghe'"><i class="uil uil-headphones"></i> {{ numberFormat(stickyPeople.total_listens) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  numberFormat, getRoutePrefixPeople, thousandFormat
} from "../../core/services/utils.service";

export default {
  name: "StickyPeople",
  data() {
    return {
      stickyPeople: {
        id: "",
        title: "",
        slug: "",
        avatar: null,
        object_type: "",
        total_listens: 0,
        yod: ""
      },
    }
  },
  mounted() {
    this.loadStickyPeople();
  },
  methods: {
    loadStickyPeople() {
      let query = `query {
        stickyPeople {
          id
          title
          slug
          dob
          mob
          yob
          dod
          mod
          yod
          total_listens
          avatar {
            url
          }
          object_type
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.stickyPeople) {
              this.stickyPeople = data.data.stickyPeople;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    getRoutePrefixPeople(x) {
      return getRoutePrefixPeople(x);
    },
  }
}
</script>
