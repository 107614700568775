<template>
  <div id="add-to-playlist-modal" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Thêm vào playlist</h3>
        <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <div v-if="message" class="mb-2">
          <alert v-model:message="message" v-model:status="status"/>
        </div>

        <input v-model="playlist_title" class="shadow-none with-border" placeholder="Tạo playlist mới hoặc thêm vào playlist sẵn có" id="title" type="text" autocomplete="off">
      </div>

      <div data-simplebar style="height: 250px">
        <div class="px-5">
          <div class="flex flex-1 items-start space-x-4">
            <div v-if="playlists && playlists.length">
              <div v-on:click="choosePlaylist(playlist)" v-for="(playlist, index) in playlists" :key="'addToPlaylist' + index" class="cursor-pointer flex items-center space-x-2 py-2 hover:bg-gray-100 rounded-md px-2 relative">
                <img class="w-10 h-10 object-cover rounded" :src="playlist.thumbnail ? playlist.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'" :alt="playlist.title"/>
                <span class="font-semibold">{{ playlist.title }}</span>
                <span class="w-8 h-8 absolute text-green-500" style="right: 10px" v-if="playlist_id === playlist.id">
                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="addToPlayList" class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Thêm vào playlist</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../../core/services/api.service";
import Alert from "../Alert";
import {updateMe} from "../../core/services/utils.service";

export default {
  name: "AddToPlaylist",
  components: {Alert},
  props: {
    key: String,
    playlists: Array,
    object_type: String,
    object_id: Number
  },
  data() {
    return {
      playlist_title: "",
      playlist_id: "",
      status: "",
      message: ""
    }
  },
  methods: {
    addToPlayList() {
      let query = `mutation($object_id: ID!, $object_type: String!, $playlist_id: ID, $playlist_title: String) {
        addToPlaylist(object_id: $object_id, object_type: $object_type, playlist_id: $playlist_id, playlist_title: $playlist_title)
      }`;

      ApiService.graphql(query, {
        object_id: this.object_id,
        object_type: this.object_type,
        playlist_id: this.playlist_id,
        playlist_title: this.playlist_title
      })
          .then(({data}) => {
            if (data.data && data.data.addToPlaylist) {
              this.status = "success";
              this.message = "Thêm vào playlist thành công";

              if (this.playlist_title) {
                updateMe("addToPlaylist");
              }

              this.playlist_title = "";
              this.playlist_id = "";
            } else {
              this.status = "error";
              this.message = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.status = "error";
            this.message = response.message;
          });
    },
    choosePlaylist(playlist) {
      this.playlist_id = playlist.id;
    },
    resetValue() {
      this.status = "";
      this.message = "";
      this.playlist_id = "";
      this.playlist_title = "";
    }
  },
  watch: {
    key: {
      handler() {
        this.resetValue();
      }
    }
  }
}
</script>
