<template>
  <div id="replaceHistoryModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Lịch sử bản thu</h3>
        <button class="replaceHistoryClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <table>
          <thead>
            <tr>
              <td>Người đăng</td>
              <td>File nhạc</td>
              <td>Ngày đăng</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="upload in uploads" :key="'upload' + upload.id">
              <td>
                <span class="people-in-song">
                  <user-of-song :people="[upload.file.user]" class="font-semibold"/>
                </span>
              </td>
              <td>
                <audio controls>
                  <source :src="upload.file.audio_url" type="audio/mpeg">
                </audio>
              </td>
              <td>{{ convertDate(upload.file.created_at) }}</td>
              <td class="p-2">
                <div v-if="upload.file.id == file_id"><span class="whitespace-nowrap italic text-sm font-normal">Đang sử dụng</span></div>
                <div v-if="upload.file.is_hq == 1"><span uk-tooltip="title: Bản thu VOV chất lượng cao" style="background: rgb(113, 19, 19); border-radius:3px" class="whitespace-nowrap px-1 text-white">vov-hq</span></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import UserOfSong from "./UserOfSong";
import {convertDate} from "../../core/services/utils.service";

export default {
  name: "ReplaceHistoryModal",
  components: {UserOfSong},
  props: {
    uploads: Array,
    file_id: Number
  },
  methods: {
    convertDate(x) {
      return convertDate(x);
    }
  }
}
</script>
