<template>
  <div id="updateSongYearModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Cập nhật năm sáng tác</h3>
        <button class="updateSongYearClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <form v-on:submit.prevent="updateSongYear">
        <div class="px-5 py-2">
          <input v-model="songYear" placeholder="" class="shadow-none with-border" type="text" autocomplete="off">
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <button type="submit"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Cập nhật</button>
        </div>
      </form>
     
    </div>
  </div>
</template>

<script>

import ApiService from "../core/services/api.service";

export default {
  name: "UpdateSongYearModal",
  props: {
    year: String,
    sheet_id: String
  },
  data() {
    return {
      songYear: ""
    }
  },
  methods: {
    updateSongYear() {
      let query = `mutation($sheet_id: ID!, $year: String) {
        updateSongYear(sheet_id: $sheet_id, year: $year) {
          id
        }
      }`;

      ApiService.graphql(query, {sheet_id: this.sheet_id, year: this.songYear})
          .then(({data}) => {
            if (data.data && data.data.updateSongYear) {
              this.$emit("updateSongYearDone");
              window.$(".updateSongYearClose").click();
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    }
  },
  watch: {
    year: {
      handler(newVal) {
        this.songYear = newVal;
      }
    }
  }
}
</script>
