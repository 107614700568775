<template>
  <router-link class="font-semibold" :to="{name: 'MelodyDetail', params: {slug: item.slug}}" v-for="item in melodies" :key="'melody' + item.slug" :title="item.title">
    {{ item.title }}
  </router-link>
</template>

<script>
export default {
  name: "MelodyOfSong",
  props: {
    melodies: Array
  }
}
</script>
