<template>
  <div class="tags">
    <router-link class="cursor-pointer" :title="'Tag ' + tag.name" :to="{name: 'TagDetail', params: {name: tag.slug}}" v-for="tag in tags" :key="'tag' + tag.id">
      <span class="keyword px-2">
        <span class="keyword-text">{{ tag.name }}</span>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    tags: Object
  }
}
</script>
