<template>
  <router-link class="font-semibold" :to="{name: 'FcatDetail', params: {slug: item.slug}}" v-for="item in fcats" :key="'fcat' + item.slug" :title="item.title">
    {{ item.title }}
  </router-link>
</template>

<script>
export default {
  name: "FcatOfSong",
  props: {
    fcats: Array
  }
}
</script>
