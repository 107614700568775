<template>
  <div class="card p-3" id="related-documents" v-if="documents.length">
    <h3 class="text-xl font-semibold line-clamp-1">Tư liệu liên quan</h3>
    <document-item :document="document" v-for="document in documents" :key="'document' + document.id"/>
  </div>
</template>

<script>

import DocumentItem from "./RelatedDocumentItem";
export default {
  name: "RelatedDocumentBox",
  components: {DocumentItem},
  props: {
    documents: {
      type: Array
    }
  }
}
</script>
